@import './fonts';
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import 'variables';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  font-size: $base-font-size;
}

* {
  box-sizing: border-box;
}

a {
  color: $primary-color;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
  display: block;
}